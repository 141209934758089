import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import star from "../assets/mt-star.svg";
import halfStar from "../assets/mt-star_half.svg";
import emptyStar from "../assets/mt-star_border.svg";
import "../styles/visuals.css";
import Charts from "./Charts";
import DonnutCampaign from "./DonnutCampaign";
import Swal from "sweetalert2";

function CampaignsTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filtro, setFiltro] = useState("todos");
  const [term, setTerm] = useState("");
  const [termCreator, setTermCreator] = useState("");
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [segments, setSegments] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState("");
  const [viewDetails, setViewDetails] = useState(null);
  const [registroEditado, setRegistroEditado] = useState();
  const [selectedStatus, setSelectedStatus] = useState("Todos");
  const [campaignName, setCampaignName] = useState("");

  const handleNombreChange = (e) => {
    setCampaignName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const campaignData = {
      nombre: campaignName,
      segmentos: selectedSegments,
      mensaje: "Mensaje Test",
      creador: "Marcelo Garcia Marquez",
      createdAt: Date.now(),
      score: 0,
      title: "Test Title",
      sendAt: Date.now(),
      aperturas: 0,
      inscripciones: 0,
      sendBy: "Marcelo Garcia Marquez",
      status: "segmentada",
      destinationCount: selectedSegments.reduce((total, segment) => {
        return total + (segment.detalles ? segment.detalles.length : 0);
      }, 0),
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/createCampaign",
        campaignData
      );
      console.log(response);
      toast.success("Campaña creada con éxito!", {
        style: {
          padding: "10px",
          background: "#12b155",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#12b155",
          secondary: "#FFF",
        },
      });
      axios.get(process.env.REACT_APP_API + "/campaigns").then((response) => {
        setData(response.data);
        console.log(response.data);
      });
      toggleModal();
    } catch (error) {
      toast.error("Error al crear campaña");
    }
  };

  const editarRegistro = (registro) => {
    setRegistroEditado(registro);
    setSelectedStatus(registro.Status);
  };

  const notify = () => {
    toast.success("Campaña Enviada", {
      style: {
        padding: "10px",
        background: "#12b155",
        color: "#fff",
        font: "sans-serif",
      },
      iconTheme: {
        primary: "#12b155",
        secondary: "#FFF",
      },
    });
  };

  const verRegistro = (registro) => {
    setViewDetails(registro);
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const generateStars = (score) => {
    // Asegurarse de que el puntaje esté en el rango de 0 a 100
    const clampedScore = Math.max(0, Math.min(100, score));

    // Convertir el puntaje a una escala de 0 a 5
    const scaledScore = (clampedScore / 100) * 5;

    // Redondear al número entero más cercano
    const starCount = Math.round(scaledScore);

    // Verificar si hay una estrella media
    const hasHalfStar = starCount < scaledScore;

    const stars = [];

    for (let i = 0; i < starCount; i++) {
      stars.push(<img key={i} src={star} alt={`Star ${i + 1}`} />);
    }

    if (hasHalfStar) {
      stars.push(<img key={stars.length} src={halfStar} alt={`Half Star`} />);
    }

    for (let i = stars.length; i < 5; i++) {
      stars.push(<img key={i} src={emptyStar} alt={`Empty Star ${i + 1}`} />);
    }

    return stars;
  };

  const toggleModal = () => {
    setModal(!modal);
    setSelectedStatus("Todos");
    setCampaignName("");
    setSelectedSegments([]);
    setSelectedSegment("");
  };

  const notifyCampaign = () => {
    toast.success("Campaña Enviada", {
      style: {
        padding: "10px",
        background: "#12b155",
        color: "#fff",
        font: "sans-serif",
      },
      iconTheme: {
        primary: "#12b155",
        secondary: "#FFF",
      },
    });
    toggleModal();
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "/getSegments")
      .then((response) => {
        setSegments(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "/campaigns")
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  }

  //filtro por status
  const filteredData = data
    .filter((item) => {
      const fullName = item.nombre;
      return fullName.toLowerCase().includes(term.toLowerCase());
    })

    .filter((item) => {
      const creatorName = item.creador;
      return creatorName.toLowerCase().includes(termCreator.toLowerCase());
    })

    .filter((item) => {
      if (filtro === "todos") {
        return true;
      } else {
        return item.status === filtro;
      }
    });

  function handleChangeFilter(event) {
    event.preventDefault();
    setFiltro(event.target.value);
  }

  const handleClearFilters = () => {
    setTerm("");
    setTermCreator("");
    setFiltro("todos");
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;

  const indexOfFirtsItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirtsItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleRemoveSegment = (segmentToRemove) => {
    const updatedSegments = selectedSegments.filter(
      (segment) => segment !== segmentToRemove
    );
    setSelectedSegments(updatedSegments);
  };

  const handleSegmentSelect = () => {
    if (selectedSegment && !selectedSegments.includes(selectedSegment)) {
      setSelectedSegments([...selectedSegments, selectedSegment]);
      console.log(selectedSegments);
    }
  };

  const stateColors = {
    suscripto: "bg-success",
    invitado: "bg-warning",
    pendiente: "pendiente",
    rechazado: "bg-danger",
    aprobado: "bg-success",
    nuevo: "nuevo",
    ingresado: "ingresado",
    validar: "validar",
    informado: "informado",
  };

  const deleteRegistro = (id, nombre) => {
    Swal.fire({
      title: `Eliminar a ${nombre}`,
      text: "Esta acción no se puede deshacer ¿está Seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(process.env.REACT_APP_API + `/deleteCampaign/${id}`)
          .then(() => {
            setData(data.filter((item) => item.id !== id));
          })
          .catch((err) => console.log(err));

        toast.success("Registro Eliminado", {
          style: {
            padding: "10px",
            background: "#12b155",
            color: "#fff",
            font: "sans-serif",
          },
          iconTheme: {
            primary: "#12b155",
            secondary: "#FFF",
          },
        });
      }
    });
  };

  return (
    <div>
      {registroEditado && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-75 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Editar Campaña</h5>
                <hr />
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <label className="me-1 fw-bold">Nombre</label>
                    <input
                      className="form-control w-50"
                      value={registroEditado.Nombre}
                      type="text"
                      required
                    />
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <label className="me-1 fw-bold">Segmentos</label>
                    <div
                      style={{ height: "36px" }}
                      className="d-flex flex-wrap form-control w-25 mt-1 me-1"
                    >
                      {selectedSegments.map((segment, index) => (
                        <div key={index} className="badge bg-info rounded me-2">
                          {capitalize(segment)}
                          <span
                            className="ms-2 text-white"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveSegment(segment)}
                          >
                            &#10006;
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="mt-1 d-flex">
                      <select
                        className="form-select"
                        value={selectedSegment}
                        onChange={(e) => setSelectedSegment(e.target.value)}
                      >
                        <option value="" disabled>
                          Seleccionar segmentos
                        </option>
                        {segments.map((segment) => (
                          <option
                            key={segment.segmentID}
                            value={segment.nombre}
                          >
                            {segment.nombre}
                          </option>
                        ))}
                      </select>
                      <button
                        className="btn btn-primary ms-2"
                        onClick={handleSegmentSelect}
                      >
                        Agregar
                      </button>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <label className="me-1 fw-bold ms-1">Status</label>
                      <select
                        className="form-select w-75"
                        value={selectedStatus}
                        type="text"
                        required
                      >
                        <option>Todos</option>
                        <option>Creada</option>
                        <option>Segmentada</option>
                        <option>Enviada</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <label className="me-1 fw-bold">Titulo</label>
                    <input
                      placeholder="Hola <FIRSTNAME> <LASTNAME>, Sumate a Pension Plan!"
                      className="form-control w-75"
                      type="text"
                      required
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="me-1 fw-bold">Etiquetas Dinamicas</label>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span className="btn bg-info p-1 text-white">Nombre</span>{" "}
                      <span className="ms-1 btn bg-info p-1 text-white">
                        Apellido
                      </span>{" "}
                      <span className="ms-1 btn bg-info p-1 text-white">
                        Email
                      </span>
                    </div>
                  </div>

                  <div className="d-flex flex-column mt-3">
                    <div className="d-flex justify-content-between">
                      <label className="me-1 fw-bold">Cuerpo del mensaje</label>
                      <button className="btn btn-primary">Plantilla</button>
                    </div>
                    <textarea
                      className="form-control textareaCampaign mt-2"
                      type="text"
                      placeholder="Mensaje"
                      required
                    />
                  </div>

                  <div>
                    <button className="btn btn-primary mt-2">
                      Previsualizar
                    </button>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-secondary opacity-75"
                      onClick={() => setRegistroEditado(null)}
                    >
                      Cancelar
                    </button>
                    <button className="btn btn-success ms-2">Guardar</button>

                    <button
                      className="btn btn-warning ms-2"
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={notifyCampaign}
                    >
                      Enviar
                      <i
                        style={{ fontSize: "15px", marginLeft: "5px" }}
                        className="material-icons"
                      >
                        send
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {registroEditado && registroEditado.Status === "Enviada" && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-75 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Editar Campaña</h5>
                <hr />
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <label className="me-1 fw-bold">Nombre</label>
                    <input
                      className="form-control w-50"
                      value={registroEditado.Nombre}
                      type="text"
                      required
                    />
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <label className="me-1 fw-bold">Segmentos</label>
                    <div
                      style={{ height: "36px" }}
                      className="d-flex flex-wrap form-control w-25 mt-1 me-1"
                    >
                      {selectedSegments.map((segment, index) => (
                        <div key={index} className="badge bg-info rounded me-2">
                          {capitalize(segment)}
                          <span
                            className="ms-2 text-white"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveSegment(segment)}
                          >
                            &#10006;
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="mt-1 d-flex">
                      <select
                        className="form-select"
                        value={selectedSegment}
                        onChange={(e) => setSelectedSegment(e.target.value)}
                        disabled
                      >
                        <option value="" disabled>
                          Seleccionar segmentos
                        </option>
                        {segments.map((segment) => (
                          <option
                            key={segment.segmentID}
                            value={segment.nombre}
                          >
                            {segment.nombre}
                          </option>
                        ))}
                      </select>
                      <button
                        className="btn btn-primary ms-2"
                        onClick={handleSegmentSelect}
                        disabled
                      >
                        Agregar
                      </button>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <label className="me-1 fw-bold ms-1">Status</label>
                      <select
                        className="form-select w-75"
                        value={selectedStatus}
                        type="text"
                        required
                        disabled
                      >
                        <option>Todos</option>
                        <option>Creada</option>
                        <option>Segmentada</option>
                        <option>Enviada</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <label className="me-1 fw-bold">Titulo</label>
                    <input
                      placeholder="Hola <FIRSTNAME> <LASTNAME>, Sumate a Pension Plan!"
                      className="form-control w-75"
                      type="text"
                      required
                      disabled
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="me-1 fw-bold">Etiquetas Dinamicas</label>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span className="btn bg-info p-1 text-white">Nombre</span>{" "}
                      <span className="ms-1 btn bg-info p-1 text-white">
                        Apellido
                      </span>{" "}
                      <span className="ms-1 btn bg-info p-1 text-white">
                        Email
                      </span>
                    </div>
                  </div>

                  <div className="d-flex flex-column mt-3">
                    <div className="d-flex justify-content-between">
                      <label className="me-1 fw-bold">Cuerpo del mensaje</label>
                      <button className="btn btn-primary" disabled>
                        Plantilla
                      </button>
                    </div>
                    <textarea
                      className="form-control textareaCampaign mt-2"
                      type="text"
                      placeholder="Mensaje"
                      required
                      disabled
                    />
                  </div>

                  <div>
                    <button className="btn btn-primary mt-2">
                      Previsualizar
                    </button>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-secondary opacity-75"
                      onClick={() => setRegistroEditado(null)}
                    >
                      Cancelar
                    </button>
                    <button className="btn btn-success ms-2">Guardar</button>

                    <button
                      className="btn btn-warning ms-2"
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={notifyCampaign}
                    >
                      Enviar
                      <i
                        style={{ fontSize: "15px", marginLeft: "5px" }}
                        className="material-icons"
                      >
                        send
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {viewDetails && (
        <>
          <div className="backdrop" style={{ overflowY: "auto" }}>
            <div className="position-absolute w-100 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Resumen de Campaña</h5>
                <hr />
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <p>Creada por: Marcelo Garcia Marquez</p>
                    <p>Enviada por: Marcelo Garcia Marquez</p>
                  </div>
                  <div style={{ width: "200px" }}>
                    <h5 className="mt-1">Resultados</h5>
                    <DonnutCampaign />
                  </div>
                  <hr />
                  <div style={{ width: "400px" }}>
                    <h5>Evolución de la Campaña</h5>
                    <Charts />
                  </div>
                  <hr />
                  <div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col">
                          <table
                            className="table table-hover shadow-sm p-3  bg-white rounded"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr className="bg-white bg-opacity-75 text-justify">
                                <th scope="col">Status</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Apellido</th>
                                <th scope="col">Email</th>
                                <th scope="col">Telefono</th>
                                <th scope="col">Departamento</th>
                              </tr>
                            </thead>
                            <tbody>
                              {viewDetails.segmentos &&
                                viewDetails.segmentos.map(
                                  (segmento, segmentoIndex) =>
                                    segmento.detalles &&
                                    segmento.detalles.map(
                                      (item, detalleIndex) => (
                                        <tr
                                          className="table text-justify"
                                          key={`${segmentoIndex}-${detalleIndex}`}
                                        >
                                          <td>
                                            {" "}
                                            <span
                                              className={`badge rounded-pill ${
                                                stateColors[item.status] ||
                                                "abandonado"
                                              }`}
                                            >
                                              {capitalize(item.status)}
                                            </span>
                                          </td>
                                          <td>{item.name}</td>
                                          <td>{item.lastname}</td>
                                          <td>{item.email}</td>
                                          <td>{item.phone}</td>
                                          <td>{item.area ? item.area : "-"}</td>
                                        </tr>
                                      )
                                    )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-secondary opacity-75"
                      onClick={() => setViewDetails(false)}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {modal && (
        <>
          <div className="backdrop" style={{ overflowY: "auto" }}>
            <div className="position-absolute w-100 bg-light p-4 rounded animate__animated animate__fadeIn">
              <h5 className="fw-bold">Nueva Campaña</h5>
              <hr />
              <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                  <label className="me-1 fw-bold">Nombre</label>
                  <input
                    className="form-control w-50"
                    type="text"
                    value={campaignName}
                    onChange={handleNombreChange}
                    required
                  />
                </div>
                <div className="d-flex align-items-center mt-2">
                  <label className="me-1 fw-bold">Segmentos</label>
                  <div
                    style={{ height: "36px" }}
                    className="d-flex flex-wrap form-control w-25 mt-1 me-1"
                  >
                    {selectedSegments.map((segment, index) => (
                      <div key={index} className="badge bg-info rounded me-2">
                        {capitalize(segment.nombre)}
                        <span
                          className="ms-2 text-white"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemoveSegment(segment)}
                        >
                          &#10006;
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="mt-1 d-flex">
                    <select
                      className="form-select"
                      value={selectedSegment ? selectedSegment.nombre : ""}
                      onChange={(e) => {
                        const selectedSegmentObject = segments.find(
                          (segment) => segment.nombre === e.target.value
                        );
                        setSelectedSegment(selectedSegmentObject);
                      }}
                    >
                      <option value="" disabled>
                        Seleccionar segmentos
                      </option>
                      {segments.map((segment) => (
                        <option key={segment.segmentID} value={segment.nombre}>
                          {segment.nombre}
                        </option>
                      ))}
                    </select>
                    <button
                      className="btn btn-primary ms-2"
                      onClick={handleSegmentSelect}
                    >
                      Agregar
                    </button>
                  </div>
                  <div className="d-flex align-items-center mt-1">
                    <label className="me-1 fw-bold ms-1">Status</label>
                    <select className="form-select w-75" type="text" required>
                      <option>Todos</option>
                      <option>Creada</option>
                      <option>Segmentada</option>
                      <option>Enviada</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <label className="me-1 fw-bold">Titulo</label>
                  <input
                    placeholder="Hola <FIRSTNAME> <LASTNAME>, Sumate a Pension Plan!"
                    className="form-control w-75"
                    type="text"
                    required
                  />
                </div>
                <div className="d-flex flex-column">
                  <label className="me-1 fw-bold">Etiquetas Dinamicas</label>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span className="btn bg-info p-1 text-white">Nombre</span>{" "}
                    <span className="ms-1 btn bg-info p-1 text-white">
                      Apellido
                    </span>{" "}
                    <span className="ms-1 btn bg-info p-1 text-white">
                      Email
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-column mt-3">
                  <div className="d-flex justify-content-between">
                    <label className="me-1 fw-bold">Cuerpo del mensaje</label>
                    <button className="btn btn-primary">Plantilla</button>
                  </div>
                  <textarea
                    className="form-control textareaCampaign mt-2"
                    type="text"
                    placeholder="Mensaje"
                    required
                  />
                </div>

                <div>
                  <button className="btn btn-primary mt-2">
                    Previsualizar
                  </button>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-secondary opacity-75"
                    onClick={toggleModal}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-success ms-2"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </button>

                  <button
                    className="btn btn-warning ms-2"
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={notifyCampaign}
                  >
                    Enviar
                    <i
                      style={{ fontSize: "15px", marginLeft: "5px" }}
                      className="material-icons"
                    >
                      send
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="container-fluid">
        <div className="d-flex m-2 mt-4 p-2 justify-content-between align-items-center">
          <div className="d-flex justify-content-center align-items-center">
            <h3 className="fs-2">Campañas</h3>
            <p
              className="bg-primary fs-5 text-white text-center ms-1  mb-0 animate__animated animate__fadeIn"
              style={{ width: "40px" }}
            >
              {data.length}
            </p>
          </div>
          <button
            className="fs-2 bg-primary text-white rounded-circle border-0"
            style={{ height: "50px", width: "50px" }}
            onClick={toggleModal}
          >
            +
          </button>
        </div>

        <div className="container-fluid">
          <div className="m-1">
            <p>Filtrar por</p>
          </div>

          <div className="d-flex col-12 justify-content-between align-items-center m-1">
            <div className="d-flex">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre"
                  value={term}
                  onChange={(event) => setTerm(event.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control ms-1"
                  placeholder="Creador"
                  value={termCreator}
                  onChange={(event) => setTermCreator(event.target.value)}
                />
              </div>
              <select
                className="form-select ms-2"
                style={{ width: "200px", height: "38px" }}
                value={filtro}
                onChange={handleChangeFilter}
              >
                <option value="todos">Status</option>
                <option value="creada">Creada</option>
                <option value="segmentada">Segmentada</option>
                <option value="enviada">Enviada</option>
              </select>
              <button
                className="btn btn-primary ms-1 p-2"
                style={{ height: "40px", width: "150px" }}
                onClick={handleClearFilters}
              >
                Borrar filtros
              </button>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <h5 className="m-1">Items por pagina</h5>
              <select
                value={itemsPerPage}
                className="form-select text-center"
                style={{ width: "100px" }}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                  setCurrentPage(1);
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </select>
              <span className="ms-1">
                1 - {itemsPerPage} de {data.length}
              </span>
              <button className="btn">
                <i className="material-icons fs-3" onClick={handlePrevPage}>
                  keyboard_arrow_left
                </i>
              </button>
              <button className="btn">
                <i className="material-icons fs-3" onClick={handleNextPage}>
                  keyboard_arrow_right
                </i>
              </button>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <table
                className="table table-hover shadow-sm p-3  bg-white rounded"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr className="bg-white bg-opacity-75 text-justify">
                    <th scope="col">Nombre</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Creada por</th>
                    <th scope="col">Fecha Creación</th>
                    <th scope="col">Destinatarios</th>
                    <th scope="col">Enviada por</th>
                    <th scope="col">Fecha envío</th>
                    <th scope="col">Aperturas</th>
                    <th scope="col">Inscripciones</th>
                    <th scope="col">Score</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, i) => {
                    return (
                      <tr className="table text-justify" key={i}>
                        <td>{item.nombre}</td>
                        <td>
                          {" "}
                          <span
                            style={{ width: "100%" }}
                            className={
                              item.status === "creada"
                                ? "badge  bg-primary"
                                : item.status === "segmentada"
                                ? "badge bg-info"
                                : "badge bg-success"
                            }
                          >
                            {capitalize(item.status)}
                          </span>
                        </td>
                        <td>{item.creador}</td>
                        <td>{formatDate(item.createdAt)}</td>
                        <td>
                          <span className="text-white p-2 bg-primary rounded-circle">
                            {item.destinationCount ? item.destinationCount : 0}
                          </span>
                        </td>
                        <td>{item.sendBy}</td>
                        <td>{formatDate(item.sendAt)}</td>
                        <td>
                          <span
                            className="text-white p-1"
                            style={{ backgroundColor: "#252525" }}
                          >
                            {item.aperturas}
                          </span>
                        </td>
                        <td>
                          <span
                            className="text-white p-1"
                            style={{ backgroundColor: "#252525" }}
                          >
                            {item.inscripciones}
                          </span>
                        </td>
                        <td>
                          <span className="p-2">
                            {generateStars(item.score)}
                          </span>
                        </td>
                        <td>
                          <i
                            className="material-icons"
                            onClick={() => deleteRegistro(item.id, item.nombre)}
                          >
                            delete
                          </i>
                          <i
                            className="material-icons"
                            onClick={() => editarRegistro(item)}
                          >
                            edit
                          </i>
                          <i
                            className="material-icons"
                            onClick={() => verRegistro(item)}
                          >
                            backup_table
                          </i>
                          <i className="material-icons" onClick={notify}>
                            mail
                          </i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <h5 className="m-1">Items por pagina</h5>
            <select
              value={itemsPerPage}
              className="form-select text-center"
              style={{ width: "100px" }}
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </select>
            <span className="ms-1">
              1 - {itemsPerPage} de {data.length}
            </span>
            <button className="btn">
              <i className="material-icons fs-3" onClick={handlePrevPage}>
                keyboard_arrow_left
              </i>
            </button>
            <button className="btn">
              <i className="material-icons fs-3" onClick={handleNextPage}>
                keyboard_arrow_right
              </i>
            </button>
          </div>
        </div>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}

export default CampaignsTable;
