import React, { useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import Swal from "sweetalert2";

function Apartments() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [term, setTerm] = useState("");
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [registroEditado, setRegistroEditado] = useState(null);
  const [nombre, setNombre] = useState("");
  const [nombreEditar, setNombreEditar] = useState(
    registroEditado ? registroEditado.nombre : ""
  );

  const handleNombreChange = (e) => {
    setNombre(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apartmentData = {
      NombreDepartamento: nombre,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/apartments/create",
        apartmentData
      );
      console.log(response);
      toast.success("Departamento Creado con exito");
      axios.get(process.env.REACT_APP_API + "/apartments").then((response) => {
        setData(response.data);
        console.log(response.data);
      });
      toggleModal();
    } catch (error) {
      toast.error("Error al crear al usuario");
    }
  };

  const eliminarRegistro = (AreaID, NombreDepartamento, CantidadEmpleados) => {
    Swal.fire({
      title: `Eliminar a: ${NombreDepartamento} cantidad de empleados en este registro (${CantidadEmpleados})`,
      text: "Esta acción no se puede deshacer ¿está Seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(process.env.REACT_APP_API + `/deleteApartment/${AreaID}`)
          .then(() => {
            setData(data.filter((item) => item.AreaID !== AreaID));
          })
          .catch((err) => console.log(err));

        toast.success("Registro Eliminado", {
          style: {
            padding: "10px",
            background: "#12b155",
            color: "#fff",
            font: "sans-serif",
          },
          iconTheme: {
            primary: "#12b155",
            secondary: "#FFF",
          },
        });
      }
    });
  };

  //filtro por status
  const filteredData = data.filter((item) => {
    const fullName = item.NombreDepartamento;
    return fullName.toLowerCase().includes(term.toLowerCase());
  });

  const editarRegistro = (registro) => {
    setRegistroEditado(registro);
    setNombreEditar(registro.NombreDepartamento);
  };

  const handleEdit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/updateDepartment/${registroEditado.AreaID}`,
        {
          NombreDepartamento: nombreEditar,     
        }
      );

      console.log("Registro editado correctamente", response.data);

      axios
        .get(process.env.REACT_APP_API + "/apartments")
        .then((response) => {
          setData(response.data);
          console.log(response.data);
        })

        .catch((error) => {
          console.error(error);
        });

      setRegistroEditado(null);

      toast.success("Registro editado con éxito", {
        style: {
          padding: "10px",
          background: "#12b155",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#12b155",
          secondary: "#FFF",
        },
      });
    } catch (error) {
      console.error("Error al editar el registro", error);
    }
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "/apartments")
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const toggleModal = () => {
    setModal(!modal);
    setNombre("");
  };

  const handleClearFilters = () => {
    setTerm("");
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;

  const indexOfFirtsItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirtsItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      {modal && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-25 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Nuevo departamento</h5>
                <hr />
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <label className="me-1">Nombre</label>
                    <input
                      className="form-control"
                      type="text"
                      value={nombre}
                      onChange={handleNombreChange}
                      required
                    />
                  </div>

                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-secondary opacity-75"
                      onClick={toggleModal}
                    >
                      Cerrar
                    </button>
                    <button
                      className="btn btn-primary ms-1"
                      disabled={!nombre}
                      onClick={handleSubmit}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {registroEditado && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-25 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Editar Departamento</h5>
                <hr />
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <label className="me-1">Nombre</label>
                    <input
                      className="form-control"
                      type="text"
                      value={nombreEditar}
                      onChange={(e) => setNombreEditar(e.target.value)}
                    />
                  </div>

                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-secondary opacity-75"
                      onClick={() => setRegistroEditado(null)}
                    >
                      Cerrar
                    </button>
                    <button className="btn btn-primary ms-1" onClick={handleEdit}>Aceptar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        <div className="container-fluid">
          <div className="d-flex m-2 mt-4 p-2 justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <h3 className="fs-2">Departamentos</h3>
              <p
                className="bg-success fs-5 text-white text-center ms-1  mb-0 animate__animated animate__fadeIn"
                style={{ width: "40px" }}
              >
                {data.length}
              </p>
            </div>
            <button
              className="fs-2 bg-primary text-white rounded-circle border-0"
              style={{ height: "50px", width: "50px" }}
              onClick={toggleModal}
            >
              +
            </button>
          </div>

          <div className="container-fluid">
            <div className="m-1">
              <p>Filtrar por</p>
            </div>

            <div className="d-flex col-12 justify-content-between align-items-center m-1">
              <div className="d-flex">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre"
                    value={term}
                    onChange={(event) => setTerm(event.target.value)}
                  />
                </div>

                <button
                  className="btn btn-primary ms-1 p-2"
                  style={{ height: "40px", width: "150px" }}
                  onClick={handleClearFilters}
                >
                  Borrar filtros
                </button>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <h5 className="m-1">Items por pagina</h5>
                <select
                  value={itemsPerPage}
                  className="form-select text-center"
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                </select>
                <span className="ms-1">
                  1 - {itemsPerPage} de {data.length}
                </span>
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handlePrevPage}>
                    keyboard_arrow_left
                  </i>
                </button>
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handleNextPage}>
                    keyboard_arrow_right
                  </i>
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <table
                  className="table table-hover shadow-sm p-3  bg-white rounded"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr className="bg-white bg-opacity-75 text-justify">
                      <th scope="col">Departamento</th>
                      <th scope="col">Staff</th>
                      <th scope="col" className="text-end">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, i) => {
                      return (
                        <tr className="table text-justify" key={i}>
                          <th scope="row">{item.NombreDepartamento}</th>
                          <td>{item.staffCount}</td>

                          <td className="text-end">
                            <i
                              className="material-icons"
                              onClick={() =>
                                eliminarRegistro(
                                  item.AreaID,
                                  item.NombreDepartamento,
                                  item.CantidadEmpleados
                                )
                              }
                            >
                              delete
                            </i>
                            <i
                              className="material-icons"
                              onClick={() => editarRegistro(item)}
                            >
                              edit
                            </i>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <h5 className="m-1">Items por pagina</h5>
              <select
                value={itemsPerPage}
                className="form-select text-center"
                style={{ width: "100px" }}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                  setCurrentPage(1);
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </select>
              <span className="ms-1">
                1 - {itemsPerPage} de {data.length}
              </span>
              <button className="btn">
                <i className="material-icons fs-3" onClick={handlePrevPage}>
                  keyboard_arrow_left
                </i>
              </button>
              <button className="btn">
                <i className="material-icons fs-3" onClick={handleNextPage}>
                  keyboard_arrow_right
                </i>
              </button>
            </div>
          </div>
        </div>
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
    </div>
  );
}

export default Apartments;
