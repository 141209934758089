import React, { useState, useEffect } from "react";
import Nav from "../components/Nav";
import axios from "axios";
import { Link } from "react-router-dom";
import "../styles/i-styles.css";
import "animate.css";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import DonnutOffCanvas from "../components/DonnutOffCanvas";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
import io from "socket.io-client";

// Conectarse al servidor de Socket.IO
const socket = io(process.env.REACT_APP_API);

socket.on("connect", () => {
  console.log("Conexión establecida con el servidor Socket.IO");
});

async function getAreas(setAreas) {

    const areas = await axios.get(
        process.env.REACT_APP_API + "/areas"
    );
    
    setAreas(areas.data);
}

function Audience() {
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [registroEditado, setRegistroEditado] = useState(null);
  const [areaFiltro, setAreaFiltro] = useState("areas");
  const [term, setTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState({});
  const [logData, setLogData] = useState([]);
  const [modal, setModal] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showInputWhen, setShowInputWhen] = useState(false);
  const [filtro, setFiltro] = useState("todos");
  const [areas, setAreas] = useState([]);
  const [province, setProvince] = useState("");
  const [location, setLocation] = useState("");
  const [columnaSeleccionada, setColumnaSeleccionada] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [dniExiste, setDniExiste] = useState(false);
  const [emailExiste, setEmailExiste] = useState(false);
  const [isEmailCorporativo, setIsEmailCorporativo] = useState(false);
  const [ordenamiento, setOrdenamiento] = useState({
    columna: null,
    tipo: null,
  });

  useEffect(() => {
    // Verificar si hay un usuario almacenado en localStorage al cargar la aplicación
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  //decargar imagen
  const downloadImage = (imageURL) => {
    const filename = imageURL.split("/").pop(); // Extrae el nombre de archivo de la URL

    fetch(process.env.REACT_APP_API + `/download/${filename}`, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Crea un objeto URL para el blob y crea un enlace de descarga
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadPdf = (pdfURL) => {
    const filename = pdfURL.split("/").pop(); // Extrae el nombre de archivo de la URL

    fetch(process.env.REACT_APP_API + `/downloadPDF/${filename}`, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Crea un objeto URL para el blob y crea un enlace de descarga
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateForm = () => {
    const inputs = document.querySelectorAll(".input-form[required]");

    const isAllFieldsFilled = Array.from(inputs).every((input) => input.value);

    setIsFormValid(isAllFieldsFilled);
  };

  //Obtener datos de la audiencia clickeada
  const handleItemClick = (item) => {
    setSelectedRow(item);
  };
  useEffect(() => {
    if (selectedRow) {
      axios
        .get(process.env.REACT_APP_API + `/historialActions/${selectedRow.id}`)
        .then((response) => {
          setLogData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedRow]);

  const notifyAdd = (e) => {
    toast.success("Audiencia añadida", {
      style: {
        padding: "10px",
        background: "#12b155",
        color: "#fff",
        font: "sans-serif",
      },
      iconTheme: {
        primary: "#12b155",
        secondary: "#FFF",
      },
    });
  };

  const notifyEdit = (e) => {
    toast.success("Datos editados con éxito!", {
      style: {
        padding: "10px",
        background: "#12b155",
        color: "#fff",
        font: "sans-serif",
      },
      iconTheme: {
        primary: "#12b155",
        secondary: "#FFF",
      },
    });
  };

  const notifyDelete = (e) => {
    toast.error("Empleado eliminado con éxito", {
      style: {
        padding: "10px",
        background: "#d34964",
        color: "#fff",
        font: "sans-serif",
      },
      iconTheme: {
        primary: "#d34964",
        secondary: "#FFF",
      },
    });
  };

  socket.on(
    "server:modificado",
    (data) => {
      console.log(data);

      const fetchData = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_API + "/audience"
          );
          setData(response.data);
          const areasFromApi = [
            ...new Set(
              response.data.reduce((acc, curr) => [...acc, curr.area], [])
            ),
          ];
          setAreas(areasFromApi);

          setIsLoaded(true);
        } catch (err) {
          console.log(err);
        }
      };

      if (!isLoaded) {
        fetchData();
      }
    },
    [isLoaded]
  );

  socket.on(
    "server:toPending",
    (data) => {
      console.log(data);

      const fetchData = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_API + "/audience"
          );
          setData(response.data);
          await getAreas(setAreas);

          setIsLoaded(true);
        } catch (err) {
          console.log(err);
        }
      };

      if (!isLoaded) {
        fetchData();
      }
    },
    [isLoaded]
  );

  socket.on(
    "server:aprobado",
    (data) => {
      console.log(data);

      const fetchData = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_API + "/audience"
          );
          setData(response.data);
          await getAreas(setAreas);

          setIsLoaded(true);
        } catch (err) {
          console.log(err);
        }
      };

      if (!isLoaded) {
        fetchData();
      }
    },
    [isLoaded]
  );

  socket.on(
    "server:creado",
    (data) => {
      console.log(data);

      const fetchData = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_API + "/audience"
          );
          setData(response.data);
          await getAreas(setAreas)

          setIsLoaded(true);
        } catch (err) {
          console.log(err);
        }
      };

      if (!isLoaded) {
        fetchData();
      }
    },
    [isLoaded]
  );

  //Gettear empleados API REMOTE
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/audience"
        );
        setData(response.data);
        await getAreas(setAreas)

        setIsLoaded(true);
      } catch (err) {
        console.log(err);
      }
    };

    if (!isLoaded) {
      fetchData();
    }
  }, [isLoaded]);

  //Handle Record
  const handleAddNewRecord = (newRecord) => {
    setData([...data, newRecord]);
    setCurrentPage(Math.ceil(data.length / itemsPerPage));
  };

  //import IMG
  // Modificamos la función para devolver un objeto con las URLs de imagen separadas
  async function uploadImages(file1, file2) {
    const formData = new FormData();
    formData.append("images", file1);
    formData.append("images", file2);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/upload-images",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { imageURL1, imagelURL2 } = response.data;
      console.log("Image URLs:", imageURL1, imagelURL2); // Agregamos esta línea para ver las URLs de imagen
      if (imageURL1 && imagelURL2) {
        return { imageURL1, imagelURL2 }; // Devolvemos las URLs de imagen por separado
      } else {
        throw new Error("No se encontraron URLs de imagen en la respuesta");
      }
    } catch (err) {
      console.log("Error en uploadImages:", err.response.data); // Agregamos esta línea para ver la respuesta completa en caso de error
      throw new Error("Error al cargar la imagen");
    }
  }

  //Importar Empleados Manualmente
  async function handleSubmit(e) {
    e.preventDefault();

    const file1 = e.target.file1.files[0];
    const file2 = e.target.file2.files[0];
    let imageURLs = null;

    if (file1 && file2) {
      try {
        const imageResponse = await uploadImages(file1, file2);
        imageURLs = imageResponse; // Asignamos el objeto con las URLs de imagen
      } catch (error) {
        console.log(error);
        // Mostrar el Toast de error
        toast.error("Ocurrió un error al cargar las imágenes", {
          style: {
            padding: "10px",
            background: "#d34964",
            color: "#fff",
            font: "sans-serif",
          },
          iconTheme: {
            primary: "#d34964",
            secondary: "#FFF",
          },
        });
        return;
      }
    }

    const formData = {
      dni: e.target.dni.value,
      name: e.target.name.value,
      lastname: e.target.lastname.value,
      email: e.target.email.value,
      emailSyngenta: e.target.emailSyngenta.value,
      dob: e.target.dob.value,
      address: e.target.address.value,
      address2: e.target.address2.value,
      zipCode: e.target.zipCode.value,
      location: e.target.location.value,
      province: e.target.province.value,
      area: e.target.area.value,
      ingress: e.target.ingress.value,
      status: e.target.status.value,
      phone: e.target.phone.value,
      phone2: e.target.phone2.value,
      cuil: e.target.cuil.value,
      added: Date.now(),
      emailsSent: 0,
      imageURL1: imageURLs ? imageURLs.imageURL1 : null,
      imagelURL2: imageURLs ? imageURLs.imagelURL2 : null,
    };

    const zipCodeForm = e.target.zipCode.value;

    if (zipCodeForm.trim() !== "") {
      // Only make the request if the zip code is not empty
      const response = await axios.get(
        process.env.REACT_APP_GEO + `/getByZipCode/${zipCodeForm}`
      );
      const data = response.data;
      if (data.length > 0) {
        const { province, location } = data[0];
        formData.province = province;
        formData.location = location;
      }
    }

    const addResponse = await axios.post(
      process.env.REACT_APP_API + "/add",
      formData
    );
    console.log(formData);
    console.log(addResponse);

    // Agregar el nuevo registro a la lista de clientes
    handleAddNewRecord(formData);
    notifyAdd();
    toggleModal();
  }

  //Comportamiento formulario
  const handleZipCodeChange = (e) => {
    const zipCodeForm = e.target.value;
    if (zipCodeForm.length >= 4) {
      axios
        .get(process.env.REACT_APP_GEO + `/getByZipCode/${zipCodeForm}`)
        .then((response) => {
          const data = response.data; // Obtener la respuesta completa
          if (data.length > 0) {
            const { province, location } = data[0]; // Obtener los valores del primer objeto en la matriz
            setProvince(province);
            setLocation(location);
          } else {
            // No se encontraron resultados para el código postal
            setProvince("");
            setLocation("");

            showAddLocationAlert(zipCodeForm);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleZipCodeChangeEdit = (e) => {
    const zipCodeForm = e.target.value;
    if (zipCodeForm.length >= 4) {
      axios
        .get(process.env.REACT_APP_GEO + `/getByZipCode/${zipCodeForm}`)
        .then((response) => {
          const data = response.data;
          if (data.length > 0) {
            const { province, location } = data[0];
            setRegistroEditado({
              ...registroEditado,
              zipCode: zipCodeForm,
              location: location,
              province: province,
            });
          } else {
            setRegistroEditado({
              ...registroEditado,
              zipCode: zipCodeForm,
              location: "",
              province: "",
            });
            showAddLocationAlert(zipCodeForm);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const showAddLocationAlert = (zipCode) => {

    const provinces = [
      "Buenos Aires",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Córdoba",
      "Corrientes",
      "Entre Ríos",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquén",
      "Río Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucumán",
    ];

    Swal.fire({
      title: "Código Postal no encontrado",
      html: `
        <p>Por favor, ingresa los siguientes datos:</p>
        <input id="swal-zipcode" class="swal2-input" placeholder="Código Postal" required value="${zipCode}">
        <input id="swal-location" class="swal2-input" placeholder="Localidad" required>
        <select id="swal-province" class="swal2-input" required>
          ${provinces.map((province) => `<option>${province}</option>`)}
        </select>
      `,
      focusConfirm: false,
      confirmButtonText: "Agregar",
      confirmButtonColor: "#123f8D",
      preConfirm: () => {
        const zipcode = Swal.getPopup().querySelector("#swal-zipcode").value;
        const location = Swal.getPopup().querySelector("#swal-location").value;
        const province = Swal.getPopup().querySelector("#swal-province").value;

        if (!zipcode || !location || !province) {
          Swal.showValidationMessage("Por favor, completa todos los campos.");
          return false;
        }

        const formData = {
          cp: zipcode,
          province,
          location,
        };
        addLocation(formData);
      },
    });
  };
  const handleFileChange1 = (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];
    const filePreview = document.getElementById("file-preview1");

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = document.createElement("img");
        img.src = e.target.result;
        img.className = "file-preview-image";
        filePreview.innerHTML = "";
        filePreview.appendChild(img);
      };

      reader.readAsDataURL(file);
    } else {
      filePreview.innerHTML = "";
    }
  };

  const handleFileChange2 = (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];
    const filePreview = document.getElementById("file-preview2");

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = document.createElement("img");
        img.src = e.target.result;
        img.className = "file-preview-image";
        filePreview.innerHTML = "";
        filePreview.appendChild(img);
      };

      reader.readAsDataURL(file);
    } else {
      filePreview.innerHTML = "";
    }
  };
  const addLocation = async (formData) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_GEO + "/addZipCode",
        formData
      );
      console.log(response);
      toast.success("Código postal añadido con éxito!", {
        style: {
          padding: "10px",
          background: "#12b155",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#12b155",
          secondary: "#FFF",
        },
      });
      setLocation(formData.location);
      setProvince(formData.province);
    } catch (error) {
      console.log(error);
      // Mostrar el Toast de error
      toast.error("Ocurrió un error al agregar la ubicación", {
        style: {
          padding: "10px",
          background: "#d34964",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#d34964",
          secondary: "#FFF",
        },
      });
    }
  };
  const apiRemote = process.env.REACT_APP_API;

  // Editar datos de empleado
  const guardarCambios = async (e) => {
    e.preventDefault();

    // Copiar los datos del registroEditado para modificar el objeto antes de enviarlo
    const registroModificado = { ...registroEditado };

    try {
      const file1 = e.target.file1.files[0];
      const file2 = e.target.file2.files[0];
      let imageURLs = null;

      if (file1 && file2) {
        try {
          const imageResponse = await uploadImages(file1, file2);
          imageURLs = imageResponse;
          console.log(imageURLs); // Asignamos el objeto con las URLs de imagen

          registroModificado.imageURL1 = imageURLs.imageURL1;
          registroModificado.imagelURL2 = imageURLs.imagelURL2;
        } catch (error) {
          console.log(error);
          // Mostrar el Toast de error
          toast.error("Ocurrió un error al cargar las imágenes", {
            style: {
              padding: "10px",
              background: "#d34964",
              color: "#fff",
              font: "sans-serif",
            },
            iconTheme: {
              primary: "#d34964",
              secondary: "#FFF",
            },
          });
          return;
        }
      }
      axios
        .put(`${apiRemote}/update/${registroModificado.id}`, registroModificado)
        .then(() => {
          setData(
            data.map((elemento) =>
              elemento.id === registroModificado.id
                ? registroModificado
                : elemento
            )
          );
          setLogData(data);
          setRegistroEditado((prevState) => ({
            ...prevState,
            imageURL1: registroModificado.imageURL1,
            imageURL2: registroModificado.imageURL2,
          }));
          setRegistroEditado(null);
        })
        .catch((error) => console.log(error));

      if (registroEditado) {
        notifyEdit();
      }
    } catch (error) {
      console.log(error);
      // Manejar el error en caso de que ocurra algún problema con la subida de imágenes
    }
  };
  const aceptarSub = (registro) => {
    const registroModificado = { ...registro };
    registroModificado.status = "suscripto";
    registroModificado.aprobbed = new Date().toLocaleDateString();

    axios
      .put(`${apiRemote}/accept/${registroModificado.id}`, registroModificado)
      .then(() => {
        setData((prevData) =>
          prevData.map((elemento) =>
            elemento.id === registroModificado.id
              ? registroModificado
              : elemento
          )
        );
        setLogData(data);

        // Resto de tu código aquí...
      })
      .catch((error) => console.log(error));

    toast.success("Registro suscripto", {
      style: {
        padding: "10px",
        background: "#12b155",
        color: "#fff",
        font: "sans-serif",
      },
      iconTheme: {
        primary: "#12b155",
        secondary: "#FFF",
      },
    });
  };

  //rechazo de subs y manejo de justificativos
  const rechazarSub = (registro) => {
    Swal.fire({
      title: `Rechazar Solicitud`,
      html: `
        <select id="justificacion" class="swal2-select" placeholder="Justificación del rechazo">
          <option value="opcion1">Selecciona una opcion</option>
          <option value="Documentación incompleta">Documentación incompleta</option>
          <option value="Documentación errónea">Documentación errónea</option>
          <option value="Datos Erróneos">Datos Erróneos</option>
          <option value="No trabaja mas en la empresa">No trabaja mas en la empresa</option>
          <option value="Se arrepintió">Se arrepintió</option>
          <option value="Otro">Otro</option>
        </select>
      `,
      showCancelButton: true,
      confirmButtonText: "Rechazar",
      preConfirm: () => {
        const justificacion =
          Swal.getPopup().querySelector("#justificacion").value;
        return { justificacion };
      },
    }).then((result) => {
      if (!result.isConfirmed) {
        return;
      }

      const registroModificado = {
        ...registro,
        status: "rechazado",
        justificacion: result.value.justificacion, // Agregar la justificación aquí
      };

      axios
        .put(
          `${apiRemote}/reject-sub/${registroModificado.id}`,
          registroModificado
        )
        .then((response) => {
          setData((prevData) =>
            prevData.map((elemento) =>
              elemento.id === registroModificado.id
                ? registroModificado
                : elemento
            )
          );
          setLogData(data);
          console.log(response);
        })
        .catch((error) => console.log(error));

      toast.success("Registro rechazado.", {
        style: {
          padding: "10px",
          background: "#12b155",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#12b155",
          secondary: "#FFF",
        },
      });
    });
  };

  const rollBackSub = (registro) => {
    const registroModificado = { ...registro };
    registroModificado.status = "pendiente";

    axios
      .put(`${apiRemote}/rollBack/${registroModificado.id}`, registroModificado)
      .then(() => {
        setData((prevData) =>
          prevData.map((elemento) =>
            elemento.id === registroModificado.id
              ? registroModificado
              : elemento
          )
        );
        setLogData(data);

        // Resto de tu código aquí...
      })
      .catch((error) => console.log(error));

    toast.success("Registro recuperado.", {
      style: {
        padding: "10px",
        background: "#12b155",
        color: "#fff",
        font: "sans-serif",
      },
      iconTheme: {
        primary: "#12b155",
        secondary: "#FFF",
      },
    });
  };

  const editarRegistro = (registro) => {
    setRegistroEditado(registro);
  };

  const eliminarRegistro = (id, name, lastname) => {
    Swal.fire({
      title: `Eliminar a ${name} ${lastname}`,
      text: "Esta acción no se puede deshacer ¿está Seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // Llamado a la API para eliminar el registro
        axios
          .delete(`${apiRemote}/delete/${id}`)
          .then(() => {
            // Actualizar la lista de datos
            setData(data.filter((item) => item.id !== id));
            console.log(data);
          })
          .catch((err) => console.log(err));

        // Mostrar una notificación de éxito
        notifyDelete();
      }
    });
  };

  //Select Filtered

  const handleDniChange = (e) => {
    const dniForm = e.target.value;
    if (dniForm.length < 7) {
      setIsFormValid(false);
    }

    if (dniForm.length >= 7) {
      axios
        .get(process.env.REACT_APP_API + `/audience/${dniForm}`)
        .then((response) => {
          if (response.data && Object.keys(response.data).length > 0) {
            // El DNI ya existe
            setDniExiste(true);
            setIsFormValid(false);
          } else {
            setDniExiste(false);
            setIsFormValid(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleEmailChange = (e) => {
    const emailForm = e.target.value;

    if (emailForm.length >= 7) {
      axios
        .get(process.env.REACT_APP_API + `/audiencia/${emailForm}`)
        .then((response) => {
          if (response.data && Object.keys(response.data).length > 0) {
            // El DNI ya existe
            setEmailExiste(true);
            setIsFormValid(false);
          } else {
            setEmailExiste(false);
            setIsFormValid(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleAddArea = () => {
    const newArea = document.querySelector('input[name="area"]').value;
    if (newArea !== "") {
      setAreas([...areas, newArea]);
      document.querySelector('input[name="area"]').value = "";
      setShowInput(false);
    }
  };

  //Función de Añadir Area
  const handleChange = (e) => {
    if (e.target.value === "Crear Nueva") {
      setShowInput(true);
    } else {
      setShowInput(false);
    }

    // Validar campos requeridos
    const inputs = document.querySelectorAll(".input-form[required]");
    let isAllFieldsFilled = true;

    inputs.forEach((input) => {
      if (input.nodeName === "SELECT") {
        // Si es un campo select
        if (input.value === "" || input.value === "default") {
          // Si la opción seleccionada es la opción por defecto, se considera como no lleno
          isAllFieldsFilled = false;
        }
      } else if (input.value === "") {
        // Si es cualquier otro campo y está vacío, se considera como no lleno
        isAllFieldsFilled = false;
      }
    });

    setIsFormValid(isAllFieldsFilled);
  };

  const handleShow = (e) => {
    if (e.target.value === "suscripto") {
      setShowInputWhen(true);
    } else {
      setShowInputWhen(false);
    }
  };

  const filteredData = data
    .filter((item) => {
      const fullName =
        item.name + " " + item.lastname + " " + item.phone + " " + item.email;
      return fullName.toLowerCase().includes(term.toLowerCase());
    })
    .filter((item) => {
      if (filtro === "todos") {
        return true;
      } else {
        return item.status === filtro;
      }
    })
    .filter((item) => {
      if (areaFiltro === "areas") {
        return true;
      } else {
        return item.area === areaFiltro;
      }
    });
  const handleFilterChange = (e) => {
    setFiltro(e.target.value);
  };

  const handleAreaChange = (e) => {
    setAreaFiltro(e.target.value);
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleClearFilters = () => {
    setTerm("");
    setFiltro("todos");
    setAreaFiltro("areas");
  };

  //Paginación

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;

  const indexOfFirtsItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirtsItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
    setShowInputWhen(false);
    setProvince("");
    setLocation("");
    setIsEmailCorporativo(false);
    setDniExiste(false);
    setEmailExiste(false);
  };

  //formato de timestamp
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  }
  function formatDateAndHours(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  function formatDateLog(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }

  //Order Functions

  const handleOrdenamientoNombres = () => {
    setColumnaSeleccionada("nombre");
    if (
      ordenamiento.columna === "nombre" &&
      ordenamiento.tipo === "ascendente"
    ) {
      setOrdenamiento({ columna: "nombre", tipo: "descendente" });
      setData((prevData) =>
        [...prevData].sort((a, b) => b.name.localeCompare(a.name))
      );
    } else {
      setOrdenamiento({ columna: "nombre", tipo: "ascendente" });
      setData((prevData) =>
        [...prevData].sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  };

  const handleOrdenamientoApellidos = () => {
    setColumnaSeleccionada("apellido");
    if (
      ordenamiento.columna === "apellido" &&
      ordenamiento.tipo === "ascendente"
    ) {
      setOrdenamiento({ columna: "apellido", tipo: "descendente" });
      setData((prevData) =>
        [...prevData].sort((a, b) => b.lastname.localeCompare(a.lastname))
      );
    } else {
      setOrdenamiento({ columna: "apellido", tipo: "ascendente" });
      setData((prevData) =>
        [...prevData].sort((a, b) => a.lastname.localeCompare(b.lastname))
      );
    }
  };

  //Emails Order

  function compararEmails(a, b) {
    const emailA = a.email.toLowerCase();
    const emailB = b.email.toLowerCase();

    const nombreUsuarioA = emailA.split("@")[0];
    const nombreUsuarioB = emailB.split("@")[0];
    const comparacionUsuario = nombreUsuarioA.localeCompare(nombreUsuarioB);

    if (comparacionUsuario !== 0) {
      return comparacionUsuario;
    } else {
      const dominioA = emailA.split("@")[1];
      const dominioB = emailB.split("@")[1];
      return dominioA.localeCompare(dominioB);
    }
  }
  const handleOrdenamientoEmails = () => {
    setColumnaSeleccionada("email");
    if (
      ordenamiento.columna === "email" &&
      ordenamiento.tipo === "ascendente"
    ) {
      setOrdenamiento({ columna: "email", tipo: "descendente" });
      setData((prevData) => [...prevData].sort(compararEmails).reverse());
    } else {
      setOrdenamiento({ columna: "email", tipo: "ascendente" });
      setData((prevData) => [...prevData].sort(compararEmails));
    }
  };

  //Date Order
  function compararFechas(a, b) {
    const fechaA = new Date(a.fecha).getTime();
    const fechaB = new Date(b.fecha).getTime();

    return fechaA - fechaB;
  }
  const handleOrdenamientoFechas = () => {
    if (
      ordenamiento.columna === "agregado" &&
      ordenamiento.tipo === "ascendente"
    ) {
      setOrdenamiento({ columna: "agregado", tipo: "descendente" });
      setData((prevData) => {
        const newData = [...prevData].sort(compararFechas).reverse();
        // Cambia el icono después de ordenar los datos
        setColumnaSeleccionada("agregado");
        return newData;
      });
    } else {
      setOrdenamiento({ columna: "agregado", tipo: "ascendente" });
      setData((prevData) => {
        const newData = [...prevData].sort(compararFechas);
        // Cambia el icono después de ordenar los datos
        setColumnaSeleccionada("agregado");
        return newData;
      });
    }
  };

  const handleOrdenamientoArea = () => {
    setColumnaSeleccionada("area");
    if (ordenamiento.columna === "area" && ordenamiento.tipo === "ascendente") {
      setOrdenamiento({ columna: "area", tipo: "descendente" });
      setData((prevData) =>
        [...prevData].sort((a, b) => b.area.localeCompare(a.area))
      );
    } else {
      setOrdenamiento({ columna: "area", tipo: "ascendente" });
      setData((prevData) =>
        [...prevData].sort((a, b) => a.area.localeCompare(b.area))
      );
    }
  };
  const handleEmailCorporateChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.includes("@syngenta.com")) {
      setIsEmailCorporativo(false);
      setIsFormValid(true);
    } else {
      setIsEmailCorporativo(true);
      setIsFormValid(false);
    }
  };

  const handleStatusChange = (e) => {
    setRegistroEditado({
      ...registroEditado,
      status: e.target.value, // Guardar el valor seleccionado en el estado
    });
  };

  function limitEmail(email) {
    if (email.length <= 20) {
      return email;
    } else {
      return email.substring(0, 20) + "...";
    }
  }

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Define las columnas base y adicionales
    const baseColumns = [
      "DNI",
      "Cuit",
      "Nombre",
      "Apellido",
      "Fecha de nacimiento",
      "Email 1",
      "Email 2",
      "Telefono 1",
      "Telefono 2",
      "Dirección",
      "Area",
      "Ingreso",
      "Status",
      "Perfil", // Agregamos estas columnas sin importar el estado
      "% Deducible", // Agregamos estas columnas sin importar el estado
    ];

    const wsData = [
      baseColumns,
      ...currentItems.map((item) => {
        const row = [
          item.dni,
          item.cuil,
          item.name,
          item.lastname,
          formatDate(item.dob),
          item.email,
          item.emailSyngenta,
          item.phone,
          item.phone2,
          item.address + " " + item.address2 + ", " + item.zipCode,
          item.area,
          formatDate(item.ingress),
          item.status,
          item.profile,
          item.aports,
        ];

        // Agregamos los valores de perfil y aports en todas las filas
        return [...row];
      }),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([wbout], { type: "application/octet-stream" });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE
      window.navigator.msSaveOrOpenBlob(blob, "Grilla Audiencia.xlsx");
    } else {
      // For modern browsers
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Grilla Audiencia.xlsx";
      link.click();
    }
  };

  console.log(data);

  const stateColors = {
    suscripto: "bg-success",
    invitado: "bg-warning",
    pendiente: "pendiente",
    rechazado: "bg-danger",
    aprobado: "bg-success",
    nuevo: "nuevo",
    ingresado: "ingresado",
    validar: "validar",
    informado: "informado",
  };

  return (
    <>
      <Nav />
      <div className="container-fluid">
        <div className="container-fluid">
          <div className="d-flex m-2 mt-4 p-2 justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <h3 className="fs-2">Audiencia</h3>
              <p
                className="bg-primary fs-5 text-white text-center ms-1 mb-0 animate__animated animate__fadeIn"
                style={{ width: "40px" }}
              >
                {filteredData ? filteredData.length : data.length}
              </p>
            </div>
            <button
              className="fs-2 bg-primary text-white rounded-circle border-0"
              style={{ height: "50px", width: "50px" }}
              onClick={toggleModal}
            >
              +
            </button>
          </div>

          {modal && (
            <>
              <div className="backdrop">
                <div className="position-fixed top-50 start-50 translate-middle w-50 bg-light p-2 rounded animate__animated animate__fadeIn modal-container overflow-hidden">
                  <div className="modal-body">
                    <div>
                      <h4 className="m-2 fw-bold">
                        Formulario de Alta de Empleado
                      </h4>
                    </div>
                    <form onSubmit={handleSubmit} className="form-control">
                      <div className="d-flex justify-content-around">
                        <div style={{ width: "50%" }}>
                          <div className="input-container">
                            <div>
                              <h5
                                style={{
                                  color: "#5E9CB8",
                                  fontWeight: "bold",
                                }}
                              >
                                Datos Personales
                              </h5>

                              <label>
                                DNI{" "}
                                <span className="fw-bold text-danger">*</span>
                              </label>
                              <input
                                className="input-form mb-1"
                                name="dni"
                                type="text"
                                pattern="[0-9]*"
                                onKeyDown={(e) => {
                                  if (
                                    !/^[0-9]+$/.test(e.key) &&
                                    e.key !== "Tab" &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                                onChange={handleDniChange}
                              />

                              {dniExiste && (
                                <>
                                  <br />
                                  <span
                                    className="d-flex justify-content-center align-items-center fw-bold animate__animated animate__fadeIn"
                                    style={{ color: "red" }}
                                  >
                                    Este dni ya existe!
                                  </span>
                                </>
                              )}

                              <label>Cuit</label>
                              <input
                                name="cuil"
                                className="input-form mb-1"
                                type="text"
                                pattern="[0-9]*"
                                onKeyDown={(e) => {
                                  if (
                                    !/^[0-9]+$/.test(e.key) &&
                                    e.key !== "Tab" &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              <br />
                              <label>
                                Nombre{" "}
                                <span className="fw-bold text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form mb-1"
                                name="name"
                                required
                                onChange={validateForm}
                              />
                              <br />
                              <label>
                                Apellido{" "}
                                <span className="fw-bold text-danger">*</span>
                              </label>
                              <input
                                className="input-form mb-1"
                                type="text"
                                name="lastname"
                                required
                                onChange={validateForm}
                              />
                              <br />
                              <label>F. de Nac.</label>
                              <input
                                className="input-form mb-1"
                                type="date"
                                name="dob"
                                onChange={validateForm}
                                max={new Date().toISOString().split("T")[0]}
                              />
                              <br />
                              <label>Domicilio (Linea 1)</label>
                              <input
                                type="text"
                                name="address"
                                className="input-form mb-1"
                                onChange={validateForm}
                              />
                              <label>Domicilio (Linea 2)</label>
                              <input
                                type="text"
                                name="address2"
                                className="input-form mb-1"
                              />
                              <label>Cod. Postal</label>
                              <input
                                className="input-form mb-1"
                                type="text"
                                onChange={handleZipCodeChange}
                                name="zipCode"
                                pattern="[0-9]*"
                                onKeyDown={(e) => {
                                  if (
                                    !/^[0-9]+$/.test(e.key) &&
                                    e.key !== "Tab" &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />

                              <label>Localidad</label>
                              <input
                                type="text"
                                name="location"
                                className="input-form mb-1"
                                defaultValue={location}
                                readOnly
                              />

                              <label>Provincia</label>
                              <input
                                type="text"
                                name="province"
                                className="input-form"
                                defaultValue={province}
                                readOnly
                              />
                            </div>
                          </div>

                          <div className="input-container">
                            <div>
                              <h5
                                style={{
                                  color: "#5E9CB8",
                                  fontWeight: "bold",
                                }}
                              >
                                Datos de Contacto
                              </h5>

                              <label>
                                Email Personal{" "}
                                <span className="fw-bold text-danger">*</span>
                              </label>
                              <input
                                className="input-form"
                                type="email"
                                name="email"
                                required
                                onChange={handleEmailChange}
                              />
                              {emailExiste && (
                                <>
                                  <br />
                                  <span
                                    className="d-flex justify-content-center align-items-center fw-bold animate__animated animate__fadeIn"
                                    style={{ color: "red" }}
                                  >
                                    Este Email ya existe!
                                  </span>
                                </>
                              )}
                              <label>Email Corporativo</label>
                              <input
                                className="input-form mb-1"
                                type="email"
                                name="emailSyngenta"
                                onChange={handleEmailCorporateChange}
                              />
                              {isEmailCorporativo && (
                                <>
                                  <br />
                                  <span
                                    className="d-flex justify-content-center align-items-center fw-bold animate__animated animate__fadeIn"
                                    style={{ color: "red" }}
                                  >
                                    Este Email no es corporativo!
                                  </span>
                                </>
                              )}

                              <br />
                              <label>
                                Teléfono{" "}
                                <span className="fw-bold text-danger">*</span>
                              </label>
                              <input
                                className="input-form mb-1"
                                name="phone"
                                type="tel"
                                required
                                onChange={validateForm}
                              />
                              <br />
                              <label style={{ fontSize: "14px" }}>
                                Teléfono 2
                              </label>
                              <input
                                className="input-form"
                                name="phone2"
                                type="number"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-0" style={{ width: "50%" }}>
                          <div className="input-container">
                            <h5
                              style={{
                                color: "#5E9CB8",
                                fontWeight: "bold",
                              }}
                            >
                              Datos Laborales
                            </h5>
                            <div>
                              <label>Ingreso</label>
                              <input
                                className="input-form mb-1"
                                name="ingress"
                                type="date"
                                max={new Date().toISOString().split("T")[0]}
                                onChange={validateForm}
                              />
                              <br />
                              <label>Area</label>
                              <select
                                className="input-form"
                                name="area"
                                onChange={handleChange}
                              >
                                <option selected value="">
                                  Selecciona un área
                                </option>
                                <option>Crear Nueva</option>
                                {areas.map((area, index) => (
                                  <option key={index} value={area}>
                                    {area}
                                  </option>
                                ))}
                              </select>
                              {showInput && (
                                <>
                                  <br />
                                  <label>Nueva Área</label>
                                  <input
                                    className="input-form"
                                    type="text"
                                    name="area"
                                    required
                                    onChange={validateForm}
                                  />
                                  <div className="d-flex justify-content-end">
                                    <button
                                      className="btn btn-primary mt-1"
                                      onClick={handleAddArea}
                                    >
                                      Añadir
                                    </button>
                                  </div>
                                  <br />
                                </>
                              )}
                            </div>
                          </div>

                          <div className="input-container">
                            <h5
                              style={{
                                color: "#5E9CB8",
                                fontWeight: "bold",
                                marginTop: "5px",
                              }}
                            >
                              Suscripción
                            </h5>
                            <div>
                              <label>Status</label>
                              <select
                                onChange={handleShow}
                                className="input-form mb-1"
                                name="status"
                                defaultValue="nuevo"
                              >
                                <option name="status" value="nuevo">
                                  Nuevo
                                </option>
                                <option name="status" value="invitado">
                                  Invitado
                                </option>
                                <option name="status" value="suscripto">
                                  Suscripto
                                </option>
                                <option name="status" value="abandonado">
                                  Abandonado
                                </option>
                                <option name="status" value="pendiente">
                                  Pendiente
                                </option>
                                <option name="status" value="validar">
                                  Validar
                                </option>
                                <option name="status" value="informado">
                                  Informado
                                </option>
                                <option name="status" value="rechazadoo">
                                  Rechazado
                                </option>
                              </select>
                              {showInputWhen && (
                                <>
                                  <br />
                                  <label>Perfil</label>
                                  <input
                                    className="input-form mb-1"
                                    type="text"
                                  />
                                  <br />
                                  <label>Deducible</label>
                                  <input className="input-form" type="text" />
                                </>
                              )}
                            </div>
                          </div>

                          <div className="input-container">
                            <h5
                              style={{
                                color: "#5E9CB8",
                                fontWeight: "bold",
                                marginTop: "5px",
                                textAlign: "center",
                              }}
                            >
                              Archivos
                            </h5>
                            <div>
                              <label htmlFor="file1">DNI Frente</label>
                              <div className="file-input">
                                <input
                                  type="file"
                                  id="file1"
                                  className="input-file"
                                  name="file1"
                                  onChange={handleFileChange1}
                                  accept=".png, .jpg, .jpeg"
                                />
                                <label
                                  htmlFor="file1"
                                  className="btn btn-primary text-center"
                                >
                                  Examinar
                                </label>
                              </div>
                              <div id="file-preview1"></div>
                            </div>

                            <div>
                              <label htmlFor="file2">DNI Reverso</label>
                              <div className="file-input">
                                <input
                                  type="file"
                                  id="file2"
                                  className="input-file"
                                  name="file2"
                                  onChange={handleFileChange2}
                                  accept=".png, .jpg, .jpeg"
                                />
                                <label
                                  htmlFor="file2"
                                  className="btn btn-primary text-center"
                                >
                                  Examinar
                                </label>
                              </div>
                              <div id="file-preview2"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          onClick={toggleModal}
                          className="btn btn-secondary opacity-75 me-2"
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={!isFormValid}
                        >
                          Guardar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="container-fluid">
            <div className="m-1">
              <p>Filtrar por</p>
            </div>
            <div className="d-flex col-12 justify-content-between align-items-center m-1">
              <div className="d-flex">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre, Apellido, Teléfono, Email"
                    value={term}
                    onChange={(event) => setTerm(event.target.value)}
                  />
                </div>
                <select
                  className="form-select ms-1"
                  style={{ width: "200px", height: "38px" }}
                  name="filtro"
                  value={filtro}
                  onChange={handleFilterChange}
                >
                  <option value="todos">Todos</option>
                  <option value="nuevo">Nuevo</option>
                  <option value="invitado">Invitado</option>
                  <option value="suscripto">Suscripto</option>
                  <option value="abandonado">Abandonado</option>
                  <option value="pendiente">Pendiente</option>
                  <option value="validar">Validar</option>
                  <option value="informado">Informado</option>
                  <option value="rechazado">Rechazado</option>
                </select>
                <select
                  className="form-select ms-1"
                  style={{ width: "200px", height: "38px" }}
                  name="area"
                  value={areaFiltro}
                  onChange={handleAreaChange}
                >
                  <option value="areas">Todas</option>
                  {areas.map((area, index) => (
                    <option key={index} value={area}>
                      {area}
                    </option>
                  ))}
                </select>
                <button
                  className="btn btn-primary ms-1 p-2"
                  style={{ height: "40px", width: "150px" }}
                  onClick={handleClearFilters}
                >
                  Borrar filtros
                </button>

                <button
                  className="text-white btn ms-1"
                  style={{ height: "40px", background: "#6e12cb" }}
                >
                  <i className="material-icons" onClick={exportToExcel}>
                    download
                  </i>
                </button>
              </div>

              <div className="d-flex align-items-center">
                <h5 className="m-1">Items por pagina</h5>
                <select
                  value={itemsPerPage}
                  className="form-select text-center"
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                {currentPage === 1 ? (
                  <span className="ms-1">
                    1 -{" "}
                    {itemsPerPage < filteredData.length
                      ? itemsPerPage
                      : filteredData.length}{" "}
                    de {filteredData.length}
                  </span>
                ) : (
                  <span className="ms-1">
                    {itemsPerPage + 1} - {filteredData.length} de{" "}
                    {filteredData.length}
                  </span>
                )}

                <button className="btn">
                  <i className="material-icons fs-3" onClick={handlePrevPage}>
                    keyboard_arrow_left
                  </i>
                </button>
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handleNextPage}>
                    keyboard_arrow_right
                  </i>
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="table-responsive">
                  <table
                    className="table table-hover shadow-sm p-3  bg-white rounded animate__animated animate__fadeIn"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr className="bg-white bg-opacity-75 text-justify">
                        <th scope="col">
                          Status
                          <button className="btn pe-none">
                            <i
                              className="material-icons d-flex align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              a
                            </i>
                          </button>
                        </th>

                        <th scope="col">
                          DNI
                          <button className="btn pe-none">
                            <i
                              className="material-icons d-flex align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              a
                            </i>
                          </button>
                        </th>
                        <th scope="col">
                          Nombre
                          <button
                            className="btn"
                            onClick={handleOrdenamientoNombres}
                          >
                            {columnaSeleccionada === "nombre" ? (
                              ordenamiento.tipo === "ascendente" ? (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_up
                                </i>
                              ) : (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_down
                                </i>
                              )
                            ) : (
                              <i
                                className="material-icons d-flex align-items-center"
                                style={{ fontSize: "14px" }}
                              >
                                drag_handle
                              </i>
                            )}
                          </button>
                        </th>
                        <th scope="col">
                          Apellido
                          <button
                            className="btn"
                            onClick={handleOrdenamientoApellidos}
                          >
                            {columnaSeleccionada === "apellido" ? (
                              ordenamiento.tipo === "ascendente" ? (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_up
                                </i>
                              ) : (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_down
                                </i>
                              )
                            ) : (
                              <i
                                className="material-icons d-flex align-items-center"
                                style={{ fontSize: "14px" }}
                              >
                                drag_handle
                              </i>
                            )}
                          </button>
                        </th>
                        <th scope="col">
                          Email
                          <button
                            className="btn"
                            onClick={handleOrdenamientoEmails}
                          >
                            {columnaSeleccionada === "email" ? (
                              ordenamiento.tipo === "ascendente" ? (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_up
                                </i>
                              ) : (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_down
                                </i>
                              )
                            ) : (
                              <i
                                className="material-icons d-flex align-items-center"
                                style={{ fontSize: "14px" }}
                              >
                                drag_handle
                              </i>
                            )}
                          </button>
                        </th>
                        <th scope="col">
                          Telefono
                          <button className="btn pe-none">
                            <i
                              className="material-icons d-flex align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              a
                            </i>
                          </button>
                        </th>
                        <th scope="col">
                          Area
                          <button
                            className="btn"
                            onClick={handleOrdenamientoArea}
                          >
                            {columnaSeleccionada === "area" ? (
                              ordenamiento.tipo === "ascendente" ? (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_up
                                </i>
                              ) : (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_down
                                </i>
                              )
                            ) : (
                              <i
                                className="material-icons d-flex align-items-center"
                                style={{ fontSize: "14px" }}
                              >
                                drag_handle
                              </i>
                            )}
                          </button>
                        </th>
                        <th scope="col">
                          Agregado
                          <button
                            className="btn"
                            onClick={handleOrdenamientoFechas}
                          >
                            {columnaSeleccionada === "agregado" ? (
                              ordenamiento.tipo === "ascendente" ? (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_up
                                </i>
                              ) : (
                                <i
                                  className="material-icons d-flex align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  keyboard_arrow_down
                                </i>
                              )
                            ) : (
                              <i
                                className="material-icons d-flex align-items-center"
                                style={{ fontSize: "14px" }}
                              >
                                drag_handle
                              </i>
                            )}
                          </button>
                        </th>
                        <th scope="col">
                          <span>Acciones</span>
                          <button className="btn pe-none">
                            <i
                              className="material-icons d-flex align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              a
                            </i>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((item, i) => {
                        return (
                          <tr
                            className="table text-justify"
                            key={i}
                            style={{ cursor: "pointer" }}
                          >
                            <th scope="row" key={item.id}>
                              <span
                                className={`badge rounded-pill ${
                                  stateColors[item.status] || "abandonado"
                                }`}
                              >
                                {capitalize(item.status)}
                              </span>
                            </th>
                            <td
                              onClick={() => handleItemClick(item)}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasExample"
                              aria-controls="offcanvasExample"
                            >
                              {item.dni}
                            </td>
                            <td
                              onClick={() => handleItemClick(item)}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasExample"
                              aria-controls="offcanvasExample"
                            >
                              {item.name}
                            </td>
                            <td
                              onClick={() => handleItemClick(item)}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasExample"
                              aria-controls="offcanvasExample"
                            >
                              {item.lastname}
                            </td>
                            <td
                              title={item.email}
                              onClick={() => handleItemClick(item)}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasExample"
                              aria-controls="offcanvasExample"
                            >
                              {limitEmail(item.email)}
                            </td>
                            <td
                              onClick={() => handleItemClick(item)}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasExample"
                              aria-controls="offcanvasExample"
                            >
                              {item.phone}
                            </td>
                            <td
                              onClick={() => handleItemClick(item)}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasExample"
                              aria-controls="offcanvasExample"
                            >
                              {item.area}
                            </td>

                            <td
                              onClick={() => handleItemClick(item)}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasExample"
                              aria-controls="offcanvasExample"
                            >
                              {formatDate(item.added)}
                            </td>
                            <td>
                              <i
                                className="material-icons"
                                onClick={() =>
                                  eliminarRegistro(
                                    item.id,
                                    item.name,
                                    item.lastname
                                  )
                                }
                              >
                                delete
                              </i>

                              <i
                                className="material-icons ms-2"
                                onClick={() => editarRegistro(item)}
                              >
                                edit
                              </i>
                              {item.status === "pendiente" && (
                                <>
                                  <i
                                    className="material-icons ms-2 text-white p-1 rounded animate__animated animate__fadeIn"
                                    style={{
                                      fontSize: "20px",
                                      background: "#02A724",
                                    }}
                                    onClick={() => aceptarSub(item)}
                                  >
                                    thumb_up
                                  </i>
                                  <i
                                    className="material-icons ms-2 text-white p-1 rounded animate__animated animate__fadeIn"
                                    onClick={() => rechazarSub(item)}
                                    style={{
                                      background: "#ff6961",
                                      fontSize: "20px",
                                    }}
                                  >
                                    thumb_down
                                  </i>
                                </>
                              )}

                              {item.status === "suscripto" && (
                                <i
                                  className="material-icons ms-2 animate__animated animate__fadeIn"
                                  onClick={() => rollBackSub(item)}
                                >
                                  settings_backup_restore
                                </i>
                              )}

                              {item.status === "rechazado" && (
                                <i
                                  className="material-icons ms-2 animate__animated animate__fadeIn"
                                  onClick={() => rollBackSub(item)}
                                >
                                  settings_backup_restore
                                </i>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <Toaster position="top-right" reverseOrder={false} />

            <div className="d-flex justify-content-end">
              <div className="d-flex align-items-center">
                <h5 className="m-1">Items por pagina</h5>
                <select
                  value={itemsPerPage}
                  className="form-select text-center"
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                {currentPage === 1 ? (
                  <span className="ms-1">
                    1 -{" "}
                    {itemsPerPage < filteredData.length
                      ? itemsPerPage
                      : filteredData.length}{" "}
                    de {filteredData.length}
                  </span>
                ) : (
                  <span className="ms-1">
                    {itemsPerPage + 1} - {filteredData.length} de{" "}
                    {filteredData.length}
                  </span>
                )}
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handlePrevPage}>
                    keyboard_arrow_left
                  </i>
                </button>
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handleNextPage}>
                    keyboard_arrow_right
                  </i>
                </button>
              </div>
            </div>
          </div>

          {registroEditado && (
            <div className="backdrop">
              <div className="position-absolute top-50 start-50 translate-middle w-50 bg-light p-2 rounded modal-container overflow-hidden animate__animated animate__fadeIn">
                <div className="d-flex justify-content-between">
                  <h4 className="fw-bold m-2">Editar Registro</h4>
                </div>
                <form className="form-control p-3" onSubmit={guardarCambios}>
                  <div className="d-flex justify-content-around">
                    <div style={{ width: "40%" }}>
                      <div className="input-container">
                        <h5 className="fw-bold" style={{ color: "#5E9CB8" }}>
                          Datos Personales
                        </h5>
                        <div className="d-flex flex-row align-items-center">
                          <label>
                            DNI <span className="fw-bold text-danger">*</span>
                          </label>
                          <input
                            className="input-form"
                            defaultValue={registroEditado.dni}
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                dni: e.target.value,
                              })
                            }
                            type="number"
                            required
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>CUIT</label>
                          <input
                            className="input-form"
                            defaultValue={registroEditado.cuil}
                            type="number"
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                cuil: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>
                            Nombre{" "}
                            <span className="fw-bold text-danger">*</span>
                          </label>
                          <input
                            className="input-form"
                            type="text"
                            name="name"
                            defaultValue={registroEditado.name}
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>
                            Apellido{" "}
                            <span className="fw-bold text-danger">*</span>
                          </label>
                          <input
                            className="input-form"
                            type="text"
                            name="lastname"
                            defaultValue={registroEditado.lastname}
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                lastname: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label style={{ fontSize: "14px" }}>F. de Nac.</label>

                          <DatePicker
                            className="input-form"
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            selected={new Date(registroEditado.dob)}
                            onChange={(date) =>
                              setRegistroEditado({
                                ...registroEditado,
                                dob: date,
                              })
                            }
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>Domicilio (Linea 1)</label>
                          <input
                            className="input-form"
                            type="text"
                            name="address"
                            defaultValue={registroEditado.address}
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                address: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>Domicilio (Linea 2)</label>
                          <input
                            className="input-form"
                            type="text"
                            name="address2"
                            defaultValue={registroEditado.address2}
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                address2: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>Codigo Postal</label>
                          <input
                            className="input-form"
                            type="number"
                            name="zipCode"
                            defaultValue={registroEditado.zipCode}
                            onChange={handleZipCodeChangeEdit}
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>Localidad</label>
                          <input
                            className="input-form"
                            type="text"
                            name="location"
                            readOnly
                            value={registroEditado.location} // Cambiar defaultValue por value
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                location: location,
                              })
                            }
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>Provincia</label>
                          <input
                            className="input-form"
                            type="text"
                            name="province"
                            readOnly
                            value={registroEditado.province}
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                location: province,
                              })
                            }
                          />
                        </div>
                        <h5
                          className="fw-bold mt-1"
                          style={{ color: "#5e9cb8" }}
                        >
                          Datos de Contacto
                        </h5>
                        <div className="d-flex flex-row align-items-center">
                          <label>
                            Email Personal
                            <span className="fw-bold text-danger">*</span>{" "}
                          </label>
                          <input
                            className="input-form"
                            type="email"
                            name="email"
                            defaultValue={registroEditado.email}
                            onChange={(e) => {
                              setRegistroEditado({
                                ...registroEditado,
                                email: e.target.value,
                              });
                              handleEmailChange(e); // Llama a la función handleEmailChange
                            }}
                            required
                          />
                        </div>
                        {emailExiste && (
                          <>
                            <span
                              className="d-flex justify-content-center align-items-center fw-bold animate__animated animate__fadeIn"
                              style={{ color: "red" }}
                            >
                              Este Email ya existe!
                            </span>
                          </>
                        )}
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>Email Corporativo</label>
                          <input
                            className="input-form"
                            type="email"
                            name="emailSyngenta"
                            defaultValue={registroEditado.emailSyngenta}
                            onChange={(e) => {
                              setRegistroEditado({
                                ...registroEditado,
                                emailSyngenta: e.target.value,
                              });
                              if (e.target.value.length >= 5) {
                                handleEmailCorporateChange(e);
                              } else {
                                // Restablecer el estado si la longitud es menor que 7
                                setIsEmailCorporativo(false);
                                setIsFormValid(false);
                              }
                            }}
                          />
                        </div>
                        {isEmailCorporativo && (
                          <>
                            <span
                              className="d-flex justify-content-center align-items-center fw-bold animate__animated animate__fadeIn"
                              style={{ color: "red" }}
                            >
                              Este Email no es corporativo!
                            </span>
                          </>
                        )}
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>
                            Telefono{" "}
                            <span className="fw-bold text-danger">*</span>
                          </label>
                          <input
                            className="input-form"
                            type="number"
                            name="phone"
                            defaultValue={registroEditado.phone}
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                phone: e.target.value,
                              })
                            }
                            required
                          />
                        </div>{" "}
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>Telefono 2</label>
                          <input
                            className="input-form"
                            type="number"
                            name="phone2"
                            defaultValue={registroEditado.phone2}
                            onChange={(e) =>
                              setRegistroEditado({
                                ...registroEditado,
                                phone2: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "40%" }}>
                      <div className="input-container">
                        <h5 className="fw-bold" style={{ color: "#5e9cb8" }}>
                          Datos Laborales
                        </h5>
                        <div className="d-flex flex-row align-items-center">
                          <label>Ingreso</label>
                          <DatePicker
                            maxDate={new Date()}
                            className="input-form"
                            dateFormat="dd/MM/yyyy"
                            selected={new Date(registroEditado.ingress)}
                            onChange={(date) =>
                              setRegistroEditado({
                                ...registroEditado,
                                ingress: date,
                              })
                            }
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <label>Area</label>
                          <select
                            className="input-form"
                            name="area"
                            onChange={(e) => {
                              setRegistroEditado({
                                ...registroEditado,
                                area: e.target.value,
                              });
                            }}
                            required
                            defaultValue={registroEditado.area}
                          >
                            {areas.map((area, index) => (
                              <option key={index} value={area}>
                                {area}
                              </option>
                            ))}
                          </select>
                        </div>
                        <h5
                          className="fw-bold mt-1"
                          style={{ color: "#5e9cb8" }}
                        >
                          Suscripción
                        </h5>
                        <div className="d-flex flex-row align-items-center">
                          <label>Status</label>
                          <select
                            className="input-form"
                            name="status"
                            value={registroEditado.status}
                            onChange={handleStatusChange}
                          >
                            <option value="nuevo">Nuevo</option>
                            <option value="invitado">Invitado</option>
                            <option value="suscripto">Suscripto</option>
                            <option value="abandonado">Abandonado</option>
                            <option value="pendiente">Pendiente</option>
                            <option value="validar">Validar</option>
                            <option value="informado">Informado</option>
                            <option value="rechazado">Rechazado</option>
                          </select>
                        </div>

                        <h5
                          className="fw-bold mt-1"
                          style={{ color: "#5e9cb8" }}
                        >
                          Archivos
                        </h5>

                        <div className="mt-2">
                          <label htmlFor="file1">DNI Frente</label>
                          <div className="file-input">
                            <input
                              type="file"
                              id="file1"
                              className="input-file"
                              name="file1"
                              onChange={handleFileChange1}
                              accept=".png, .jpg, .jpeg"
                            />
                            <label
                              htmlFor="file1"
                              className="btn btn-primary text-center"
                            >
                              Examinar
                            </label>
                          </div>
                          <div
                            id="file-preview1"
                            className="file-preview"
                          ></div>
                          <span id="file-name1" className="file-name"></span>
                          {registroEditado.imageURL1 ? (
                            <img
                              src={registroEditado.imageURL1}
                              className="mt-1"
                              alt="src"
                              style={{ width: "150px", height: "75px" }}
                            />
                          ) : (
                            <span> </span>
                          )}
                        </div>

                        <div className="mt-2">
                          <label htmlFor="file2">DNI Dorso</label>
                          <div className="file-input">
                            <input
                              type="file"
                              id="file2"
                              className="input-file"
                              name="file2"
                              onChange={handleFileChange2}
                              accept=".png, .jpg, .jpeg"
                            />
                            <label
                              htmlFor="file2"
                              className="btn btn-primary text-center"
                            >
                              Examinar
                            </label>
                          </div>
                          <div
                            id="file-preview2"
                            className="file-preview"
                          ></div>
                          {registroEditado.imagelURL2 ? (
                            <img
                              src={registroEditado.imagelURL2}
                              className="mt-1"
                              alt="src"
                              style={{ width: "150px", height: "75px" }}
                            />
                          ) : (
                            <span> </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className="btn btn-secondary me-2 opacity-75"
                      onClick={() => setRegistroEditado(null)}
                    >
                      Cancelar
                    </button>
                    <button className="btn btn-primary" type="submit">
                      Guardar cambios
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {selectedRow && (
            <div
              style={{ width: "25%" }}
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header">
                <div className="d-flex flex-column">
                  <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                    {selectedRow.name} {selectedRow.lastname}
                  </h5>
                  <div className="d-flex">
                    <span
                      style={{ width: "100px" }}
                      className={`badge rounded-pill ${
                        stateColors[selectedRow.status] || "abandonado"
                      }`}
                    >
                      {capitalize(selectedRow.status)}
                    </span>{" "}
                    <span className="ms-1">
                      {selectedRow.status === "abandonado: 3" ? (
                        <span>Carga de datos personales</span>
                      ) : (
                        ""
                      )}
                    </span>
                    <span className="ms-1">
                      {selectedRow.status === "abandonado: 4" ? (
                        <span>Carga de datos de contacto</span>
                      ) : (
                        ""
                      )}
                    </span>
                    <span className="ms-1">
                      {selectedRow.status === "abandonado: 5" ? (
                        <span>Carga de D.N.I.</span>
                      ) : (
                        ""
                      )}
                    </span>
                    <span className="ms-1">
                      {selectedRow.status === "abandonado: 6" ? (
                        <span>Elección de deducción</span>
                      ) : (
                        ""
                      )}
                    </span>
                    <span className="ms-1">
                      {selectedRow.status === "abandonado: 7" ? (
                        <span>Elección de perfil</span>
                      ) : (
                        ""
                      )}
                    </span>
                    <span className="ms-1">
                      {selectedRow.status === "abandonado: 8" ? (
                        <span>Resumen de datos</span>
                      ) : (
                        ""
                      )}
                    </span>
                    <span className="ms-1">
                      {selectedRow.status === "abandonado: 9" ? (
                        <span>Declaración Jurada</span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <ul className="nav nav-tabs mt-2" id="myTabs" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="tab1-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#tab1"
                        type="button"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        Info.
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="tab2-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#tab2"
                        type="button"
                        role="tab"
                        aria-controls="tab2"
                        aria-selected="false"
                      >
                        Docs.
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="tab3-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#tab3"
                        type="button"
                        role="tab"
                        aria-controls="tab3"
                        aria-selected="false"
                      >
                        Logs
                      </button>
                    </li>
                    {/*<li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="tab4-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#tab4"
                        type="button"
                        role="tab"
                        aria-controls="tab4"
                        aria-selected="false"
                      >
                        Stats
                      </button>
                    </li>*/}
                  </ul>
                </div>
              </div>

              <div className="offcanvas-body">
                <div className="tab-content" id="myTabsContent">
                  <div
                    className="tab-pane fade show active"
                    id="tab1"
                    role="tabpanel"
                    aria-labelledby="tab1-tab"
                  >
                    <div className="d-flex flex-column">
                      <h5 style={{ color: "#5E9CB8", fontWeight: "bold" }}>
                        Datos Personales
                      </h5>
                      <div className="text-align-center">
                        <p>
                          DNI:{" "}
                          <span className="fw-bold">{selectedRow.dni}</span>{" "}
                        </p>
                        <p>
                          CUIT:{" "}
                          <span className="fw-bold">
                            {selectedRow.cuil ? selectedRow.cuil : "-"}
                          </span>{" "}
                        </p>
                        <p>
                          Apellido:{" "}
                          <span className="fw-bold">
                            {selectedRow.lastname}
                          </span>
                        </p>
                        <p>
                          Nombre:{" "}
                          <span className="fw-bold">{selectedRow.name}</span>
                        </p>
                        <p>
                          Fecha de Nacimiento:{" "}
                          <span className="fw-bold">
                            {selectedRow.dob !== null
                              ? formatDate(selectedRow.dob)
                              : "-"}
                          </span>
                        </p>
                        <p>
                          Domicilio:{" "}
                          {selectedRow.zipCode ? (
                            <span className="fw-bold">
                              {selectedRow.address} {selectedRow.address2} ||{" "}
                              {selectedRow.location} (C
                              {selectedRow.zipCode}), {selectedRow.province}.
                            </span>
                          ) : (
                            "-"
                          )}
                        </p>
                        <h5 style={{ color: "#5E9CB8", fontWeight: "bold" }}>
                          Datos de Contacto
                        </h5>
                        <p>
                          Email:{" "}
                          <span className="fw-bold">{selectedRow.email}</span>
                        </p>
                        <p>
                          Email Syngenta:{" "}
                          <span className="fw-bold">
                            {selectedRow.emailSyngenta}
                          </span>
                        </p>
                        <p>
                          Telefono 1:{" "}
                          <span className="fw-bold">{selectedRow.phone}</span>
                        </p>
                        <p>
                          Telefono 2:{" "}
                          <span className="fw-bold">
                            {selectedRow.phone2 ? selectedRow.phone2 : "-"}
                          </span>
                        </p>
                        <h5 style={{ color: "#5E9CB8", fontWeight: "bold" }}>
                          Datos Laborales
                        </h5>
                        <p>
                          Ingreso:{" "}
                          <span className="fw-bold">
                            {selectedRow.ingress !== null
                              ? formatDate(selectedRow.ingress)
                              : "-"}
                          </span>
                        </p>
                        <p>
                          Area:{" "}
                          <span className="fw-bold">
                            {selectedRow.area ? selectedRow.area : "-"}
                          </span>
                        </p>
                        <h5 style={{ color: "#5E9CB8", fontWeight: "bold" }}>
                          Suscripción
                        </h5>
                        <p>
                          Aprobado:{" "}
                          <span className="fw-bold">
                            {selectedRow.status === "suscripto" ? (
                              selectedRow.aprobbed ? (
                                <span>
                                  {formatDateLog(selectedRow.aprobbed)}
                                </span>
                              ) : (
                                "-"
                              )
                            ) : (
                              "-"
                            )}
                          </span>
                        </p>
                        <p>
                          Onboarding:{" "}
                          <span className="fw-bold">
                            {selectedRow.onBoarding !== null
                              ? formatDate(selectedRow.onBoarding)
                              : "-"}
                          </span>
                        </p>
                        <p>
                          Deducible:{" "}
                          <span className="fw-bold">
                            {selectedRow.aports ? selectedRow.aports : "-"}
                          </span>{" "}
                        </p>
                        <p>
                          Perfil:{" "}
                          <span className="fw-bold">
                            {selectedRow.profile ? selectedRow.profile : "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab2"
                    role="tabpanel"
                    aria-labelledby="tab2-tab"
                  >
                    <div className="mt-2">
                      <div className="mt-2">
                        <div className="mt-3">
                          {selectedRow.imageURL1 && selectedRow.imagelURL2 ? (
                            <>
                              <h5
                                style={{ color: "#5E9CB8" }}
                                className="fw-bold"
                              >
                                D.N.I. Frente
                              </h5>
                              <img
                                src={selectedRow.imageURL1}
                                className="d-flex justify-content-center"
                                alt="DorsoDNI"
                                style={{ width: "320px", height: "180px" }}
                              />

                              <p>
                                Subido:{" "}
                                <span className="fw-bold">
                                  {formatDate(selectedRow.added)}
                                </span>
                              </p>
                              <p>
                                Por:{" "}
                                <span className="fw-bold">
                                  Marcelo Garcia Marquez
                                </span>
                              </p>

                              <div className="d-flex justify-content-center">
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    downloadImage(selectedRow.imageURL1)
                                  }
                                >
                                  Descargar
                                </button>
                              </div>

                              <h5
                                style={{ color: "#5E9CB8" }}
                                className="fw-bold"
                              >
                                D.N.I. Dorso
                              </h5>
                              <img
                                src={selectedRow.imagelURL2}
                                className="d-flex justify-content-center"
                                alt="DorsoDNI"
                                style={{ width: "320px", height: "180px" }}
                              />
                              <p>
                                Subido:{" "}
                                <span className="fw-bold">
                                  {formatDate(selectedRow.added)}
                                </span>
                              </p>
                              <p>
                                Por:{" "}
                                <span className="fw-bold">
                                  Marcelo Garcia Marquez
                                </span>
                              </p>
                              <div className="d-flex justify-content-center">
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    downloadImage(selectedRow.imagelURL2)
                                  }
                                >
                                  Descargar
                                </button>
                              </div>
                              <hr></hr>
                              <h5
                                style={{ color: "#5E9CB8" }}
                                className="fw-bold"
                              >
                                Declaración Jurada
                              </h5>
                              <iframe
                                title="PDF Viewer"
                                src={selectedRow.pdfURL}
                                width="100%"
                                height="300px"
                              ></iframe>
                              <div className="d-flex justify-content-center">
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    downloadPdf(selectedRow.pdfURL)
                                  }
                                >
                                  Descargar PDF
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="mt-3 ms-2">
                              <h5
                                className="fw-bold"
                                style={{ color: "#5e9cb8" }}
                              >
                                Documentación pendiente
                              </h5>
                              <hr></hr>
                              {selectedRow.pdfURL ? (
                                <>
                                  {" "}
                                  <h5
                                    style={{ color: "#5E9CB8" }}
                                    className="fw-bold"
                                  >
                                    Declaración Jurada
                                  </h5>
                                  <iframe
                                    title="PDF Viewer"
                                    src={selectedRow.pdfURL}
                                    width="100%"
                                    height="300px"
                                  ></iframe>
                                  <div className="d-flex justify-content-center">
                                    <button className="btn btn-primary">
                                      <a
                                        className="text-white  text-decoration-none fw-bold"
                                        href={selectedRow.pdfURL}
                                        download="nombre-del-archivo.pdf"
                                        style={{
                                          display: "block",
                                          marginTop: "10px",
                                        }}
                                      >
                                        Descargar PDF
                                      </a>
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <h5
                                    className="fw-bold"
                                    style={{ color: "#5e9cb8" }}
                                  >
                                    Declaración Jurada Pendiente
                                  </h5>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab3"
                    role="tabpanel"
                    aria-labelledby="tab3-tab"
                  >
                    <table
                      className="table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">
                            <h5 className="fw-bold">Fecha/Hora</h5>
                          </th>
                          <th scope="col">
                            <h5 className="fw-bold">Evento</h5>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {logData
                          .slice()
                          .reverse()
                          .map((logItem) => (
                            <tr>
                              <td>{formatDateLog(logItem.fecha)}</td>
                              <td>
                                {logItem.accionId === 1 && (
                                  <div>
                                    <span>Registro</span>
                                    <span className="fw-bold ms-1">
                                      {logItem.descripcion_accion}
                                    </span>
                                    <span> por </span>
                                    <Link className="text-decoration-none">
                                      Marcelo Garcia Marquez
                                    </Link>
                                  </div>
                                )}
                                {logItem.accionId === 2 ||
                                logItem.accionId === 3 ||
                                logItem.accionId === 5 ? (
                                  <div>
                                    <Link className="text-decoration-none">
                                      Marcelo Garcia Marquez
                                    </Link>
                                    <span className="fw-bold ms-1">
                                      {logItem.descripcion_accion} al usuario
                                    </span>
                                  </div>
                                ) : logItem.accionId === 4 &&
                                  logItem.justificacion !== null ? (
                                  <div>
                                    <Link className="text-decoration-none">
                                      Marcelo Garcia Marquez
                                    </Link>
                                    <span className="fw-bold ms-1">
                                      Rechazó motivo: {logItem.justificacion}
                                    </span>
                                  </div>
                                ) : logItem.accionId === 15 ? (
                                  <div>
                                    <span className="fw-bold">
                                      El registro ha sido:{" "}
                                      {logItem.descripcion_accion},{" "}
                                      {logItem.importName}
                                    </span>
                                  </div>
                                ) : (
                                  <>
                                    <span>Onboarding status modificado: </span>
                                    <span className="fw-bold">
                                      {logItem.descripcion_accion}
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="tab4"
                    role="tabpanel"
                    aria-labelledby="tab4-tab"
                  >
                    <div className="mt-4">
                      <div style={{ width: "111px" }}>
                        <DonnutOffCanvas />
                      </div>

                      <div className="d-flex flex-row justify-content-around ms-4">
                        <p className="fw-bold">
                          Correos
                          <br /> Enviados
                        </p>
                        <p className="fw-bold">
                          Correos
                          <br /> Leidos
                        </p>
                        <p className="fw-bold">
                          Ingresos a<br /> Digital Pension
                        </p>
                      </div>
                      <div className="mt-5">
                        <table
                          className="table table-hover"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <h6 className="fw-bold text-justify">
                                  Segmentos a los que pertenece
                                </h6>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">22/5/2018 18:05</th>
                              <td>
                                <Link to="#" className="text-decoration-none">
                                  Segmento 1
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">22/5/2018 18:05</th>
                              <td>
                                <Link to="#" className="text-decoration-none">
                                  Segmento 2
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">22/5/2018 18:05</th>
                              <td>
                                <Link to="#" className="text-decoration-none">
                                  Segmento Mock
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Audience;
